import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {WillAnimate} from '../../components/content_animate';
import {Layout} from '../../components/layout';
import {ScrollTo} from '../../components/scroll_to';

const locale = 'en';

export default ({data}: any) => (
    <Layout
        title="Contact us – Enschede in the Netherlands – Create software products together?"
        description="New software product development assignment? Contact our office in Enschede. Also when you want to take a step in your career, we would love to hear from you."
        ogImage="contact.png"
        translatedPagePath="/nl/contact/"
        locale={locale}>
        <div id="hero" className="hero hero--contact">
            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h2>
                        New <span className="highlight">assignment</span>? Email us at{' '}
                        <a href="mailto:hi@305.nl">hi@305.nl</a> <br />
                        <br />
                        Next step in your <span className="highlight">career</span>? View our{' '}
                        <a
                            title="Career website (opens in a new tab)"
                            target="_blank"
                            href="https://careers.305.nl/?lang=en"
                            rel="noreferrer">
                            vacancies and internships
                        </a>
                    </h2>
                </div>
            </div>
            <div className="hero__locations-wrapper">
                <div className="hero__column">
                    <ScrollTo
                        target="/en/contact/#enschede"
                        className="hero__location hero__location--inline"
                        title="Contact information Enschede">
                        Enschede, NL
                    </ScrollTo>
                    <ul className="list">
                        <li className="list__item">
                            <a
                                title="Google Maps"
                                href="https://www.google.com/maps/place/Label305+Enschede/@52.2247088,6.8829631,17z/data=!3m1!4b1!4m5!3m4!1s0x47b813da389ae993:0x2b88f1b9f0b67b68!8m2!3d52.2247469!4d6.8851907">
                                Hengelosestraat 86 <br /> 7514AK Enschede
                            </a>
                        </li>
                        <li className="list__item">
                            <a title="Email us" href="mailto:hi@305.nl">
                                hi@305.nl
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <ScrollTo className="hero__scroll" target="/en/contact/#enschede" position="start" />
        </div>

        <section className="section">
            <WillAnimate as="article" id="enschede" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image content__image--wide">
                            <div className="image">
                                <div className="image__content">
                                    <Img alt="Office Enschede" fluid={data.enschede.childImageSharp.fluid} />
                                </div>
                            </div>
                        </div>
                        <div className="content__copy content__copy--wide">
                            <h2>Enschede, The Netherlands</h2>
                            <p>
                                Our office is located on the ground floor of a beautiful authentic building at the
                                Hengelosestraat.
                            </p>
                            <p>
                                The city is a two hour drive from both Schiphol Airport (NL) and Düsseldorf Airport
                                (DE). When you approach Enschede by car, take exit Enschede-West on the A35 and drive
                                straight on at the first traffic lights. At the second set of traffic lights, turn left
                                and follow the "centrum" signs. Keep following the signs until you can no longer go
                                straight and turn left here towards the tunnel under the railway. Follow the road that
                                bends to the left and you will see our office on the right.
                            </p>
                            <p>
                                Traveling by public transport? Get off at the Enschede train station and take the bus
                                (or walk) to the Enschede Schuttersveld bus stop.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>
        </section>
    </Layout>
);

export const query = graphql`
    query {
        enschede: file(relativePath: {eq: "office/hengelosestraat.png"}) {
            ...fluid1600Width
        }
    }
`;
