import {Link} from 'gatsby';
import {FunctionComponent} from 'react';
import React from 'react';

interface OwnProps {
    target: string;
    className?: string;
    title?: string;
    position?: ScrollLogicalPosition;
}

export const ScrollTo: FunctionComponent<OwnProps> = ({target, position, className, title, children}) => {
    const url = target;
    const targetElement = target.slice(target.lastIndexOf('#'), target.length);

    return (
        <Link
            rel="noopener"
            to={url}
            onClick={e => {
                const element = document.querySelector(targetElement);
                if (element) {
                    e.preventDefault();
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: position || 'center',
                    });

                    if (typeof history !== 'undefined') {
                        history.pushState(null, document.title, target);
                    }
                }
            }}
            className={className}
            title={title}>
            {children}
        </Link>
    );
};
